<template>
  <main class="page-404">
    <logo />
    <div class="page-404__content">
      <div class="title">
        4<span>0</span>4
      </div>
      <p class="page-404__subtitle">
        Îmi pare rău, racer, dar pagina pe care o cauți este într-un alt castel!
      </p>
      <div class="page-404__button">
        <v-button
          @click="backToHome()"
          classes="btn-secondary btn-w-md"
        >
          Înapoi la pagina principală
        </v-button>
      </div>
    </div>
    <div class="bg-gradient" />
  </main>
</template>
<script>
import Button from '@/components/UI/Button';
import Logo from '@/components/Logo';

export default {
  name: 'Page404',
  components: {
    'v-button': Button,
    Logo,
  },
  methods: {
    backToHome() {
      this.$router.replace('/');
    },
  },
};
</script>

<style lang="scss">
  .page-404{
    width: 100%;
    min-height: 100vh;
    padding: 147px 20px 30px;
    background: rgba(49, 47, 115, 0.35);
    backdrop-filter: blur(35px);;
    display: flex;
    align-items: center;
    flex-direction: column;

    .main-logo {
      margin: 0 auto;
    }
    &__content{
      text-align: center;
      .title {
        font-size: 160px;
        color: white;
        font-weight: 700;
        span {
          color: $primary-color;
        }
        @include sm {
          font-size: 100px;
          margin-bottom: 20px;
        }
      }
    }
    &__subtitle{
      font-size: 18px;
      color: $normal-white;
      text-align: center;
      @media(max-width: 768px){
        font-size: 16px;
      }
    }
  }

</style>
